import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-external'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['22%', '2%', '26%', '22%', '2%', '26%'],
                body: [
                    [
                        { text: vm.$t('externalTraining.personal_info'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('user.namel'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.name : data.name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalUserIrrigation.father_name'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.name_of_father_husband : data.name_of_father_husband_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalUserIrrigation.mother_name'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.mother_name : data.mother_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalUserIrrigation.nid'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.nid ? vm.$n(data.nid, { useGrouping: false }) : '', style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalUserIrrigation.date_of_birth'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: dateFormat(data.date_of_bith), style: 'td', alignment: 'left' },
                        { text: vm.$t('globalTrans.gender'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.gender_name : data.gender_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.email'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'en' ? data.email : data.email, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.mat_status'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.marital_name : data.marital_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.blood_group'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.blood_name : data.blood_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.religion'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.religion_name : data.religion_name_bn, style: 'td', alignment: 'left', colSpan: 4 },
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('elearning_iabm.emergency_contact_information'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('elearning_iabm.emergency_contact_person'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.emergency_name : data.emergency_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_iabm.emergency_contact_relation'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.emergency_relation : data.emergency_relation_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_iabm.emergency_contact'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$n('0') + vm.$n(data.emergency_contact, { useGrouping: false }), style: 'td', alignment: 'left', colSpan: 4 },
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('externalTraining.present_address'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('org_pro.area_type'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.area_name : data.area_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro_division.division'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.division_name : data.division_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('org_pro_district.district'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.district_name : data.district_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro.city_corporation'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.cityCorporation_name : data.cityCorporation_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.upazilla_name : data.upazilla_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro_union.union'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.union_name : data.union_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('org_pro.pauroshoba'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.pauroshoba_name : data.pauroshoba_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro.ward'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.ward_name : data.ward_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.parmanent_address'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('org_pro.area_type'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_area_name : data.per_area_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro_division.division'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_division_name : data.per_division_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('org_pro_district.district'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_district_name : data.per_district_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro.city_corporation'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_cityCorporation_name : data.per_cityCorporation_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_upazilla_name : data.per_upazilla_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro_union.union'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_union_name : data.per_union_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('org_pro.pauroshoba'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_pauroshoba_name : data.per_pauroshoba_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('org_pro.ward'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.per_ward_name : data.per_ward_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.academic_info'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('externalTraining.highest_degree'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.name_of_highest_degree : data.name_of_highest_degree_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.board_university'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.board : data.board_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_iabm.passing_year'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$n(data.passing_year, { useGrouping: false }), style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_iabm.group'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.prof_group : data.prof_group_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_iabm.cgpa'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.cgpa, style: 'td', alignment: 'left', colSpan: 4 },
                        {},
                        {},
                        {}
                    ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
            }
        })
        if (parseInt(data.professional_info.profession_type) === 1) {
            let professionType = ''
            if (parseInt(data.professional_info.profession_type) === 1 && data.professional_info.not_here_office === 1) {
                professionType = ((vm.$i18n.locale === 'en') ? data.professional_info.office_name : data.professional_info.office_name_bn)
            }
            if (parseInt(data.professional_info.profession_type) === 1 && data.professional_info.not_here_office === 0) {
                professionType = ((vm.$i18n.locale === 'en') ? data.office_name : data.office_name_bn) + ', ' + (vm.getOrgName(data.professional_info.org_id))
            }
            pdfContent.push({
                table: {
                  widths: ['22%', '2%', '26%', '22%', '2%', '26%'],
                  body: [
                    [
                        { text: vm.$t('externalTraining.professional_info'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('elearning_iabm.profession_type'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.proType_name : data.proType_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_iabm.organization_or_office'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.org_name : data.org_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_iabm.job_type'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.job_name : data.job_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_iabm.cader_name'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.professional_info.cader_name : data.professional_info.cader_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_iabm.bcs_batch'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.batch, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.office_type_name : data.office_type_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: professionType, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.office_mail'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.professional_info.office_mail_address : data.professional_info.office_mail_address_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.designation'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.not_here_designation === 0 ? ((i18n.locale === 'en') ? data.designation_name : data.designation_name_bn) : ((i18n.locale === 'en') ? data.professional_info.designation_en : data.professional_info.designation_bn), style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.service_id'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.service_id ? vm.$n(data.professional_info.service_id, { useGrouping: false }) : '', style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.pay_grade'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.grade_name : data.grade_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.designation_c'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.professional_info.controlling_authority : data.professional_info.controlling_authority_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.telephone_c'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.telephone, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.email_c'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.email, style: 'td', alignment: 'left' }
                    ]
                  ]
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return 0
                  },
                  vLineWidth: function (i, node) {
                    return 0
                  }
                }
              })
        } else if (parseInt(data.professional_info.profession_type) === 2) {
            pdfContent.push({
                table: {
                    widths: ['22%', '2%', '26%', '22%', '2%', '26%'],
                    body: [
                        [
                            { text: vm.$t('externalTraining.professional_info'), style: 'th1', alignment: 'center', colSpan: 6 },
                            {},
                            {},
                            {},
                            {},
                            {}
                        ],
                        [
                            { text: vm.$t('elearning_iabm.profession_type'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: (i18n.locale === 'en') ? data.proType_name : data.proType_name_bn, style: 'td', alignment: 'left' },
                            { text: vm.$t('elearning_iabm.organization_or_office'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: (i18n.locale === 'en') ? data.professional_info.other_org_name : data.professional_info.other_org_name_bn, style: 'td', alignment: 'left' }
                        ],
                        [
                            { text: vm.$t('elearning_iabm.job_type'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: (i18n.locale === 'en') ? data.job_name : data.job_name_bn, style: 'td', alignment: 'left' },
                            { text: vm.$t('elearning_iabm.cader_name'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: (i18n.locale === 'en') ? data.professional_info.cader_name : data.professional_info.cader_name_bn, style: 'td', alignment: 'left' }
                        ],
                        [
                            { text: vm.$t('elearning_iabm.bcs_batch'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: data.professional_info.batch, style: 'td', alignment: 'left' },
                            { text: vm.$t('elearning_iabm.designation'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: (i18n.locale === 'en') ? data.professional_info.designation_en : data.professional_info.designation_bn, style: 'td', alignment: 'left' }
                        ],
                        [
                            { text: vm.$t('externalTraining.service_id'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: data.professional_info.service_id ? vm.$n(data.professional_info.service_id, { useGrouping: false }) : '', style: 'td', alignment: 'left' },
                            { text: vm.$t('externalTraining.pay_grade'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: (i18n.locale === 'en') ? data.grade_name : data.grade_name_bn, style: 'td', alignment: 'left' }
                        ],
                        [
                            { text: vm.$t('externalTraining.designation_c'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: data.professional_info.controlling_authority, style: 'td', alignment: 'left' },
                            { text: vm.$t('externalTraining.telephone_c'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: data.professional_info.telephone, style: 'td', alignment: 'left' }
                        ],
                        [
                            { text: vm.$t('externalTraining.email_c'), style: 'th', alignment: 'left' },
                            { text: ':', style: 'th', alignment: 'center' },
                            { text: data.professional_info.email, style: 'td', alignment: 'left', colSpan: 4 },
                            {},
                            {},
                            {}
                        ]
                    ]
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return 0
                  },
                  vLineWidth: function (i, node) {
                    return 0
                  }
                }
            })
        } else if (parseInt(data.professional_info.profession_type) === 3) {
            pdfContent.push({
                table: {
                  widths: ['22%', '2%', '26%', '22%', '2%', '26%'],
                  body: [
                    [
                        { text: vm.$t('externalTraining.professional_info'), style: 'th1', alignment: 'center', colSpan: 6 },
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('elearning_iabm.profession_type'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.proType_name : data.proType_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_iabm.organization_or_office'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.professional_info.other_org_name : data.professional_info.other_org_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_iabm.designation'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.professional_info.designation_en : data.professional_info.designation_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.designation_c'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.controlling_authority, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.telephone_c'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.telephone, style: 'td', alignment: 'left' },
                        { text: vm.$t('externalTraining.email_c'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: data.professional_info.email, style: 'td', alignment: 'left' }
                    ]
                  ]
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return 0
                  },
                  vLineWidth: function (i, node) {
                    return 0
                  }
                }
              })
        }
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                th1: {
                    fillColor: '#C6C2C1',
                    fontSize: 11,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 0, 0, 0]
              },
              org: {
                  fontSize: 13,
                  bold: true,
                  margin: [0, -25, 10, 5]
              },
              address: {
                  fontSize: 11,
                  margin: [0, 0, 0, 5]
              },
              hh: {
                  fontSize: 14,
                  bold: true,
                  margin: [10, 10, 25, 20]
              },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}

<template>
    <b-container fluid>
        <TraineerForm v-if="parseInt(trainingType) === 1"/>
        <TraineeForm v-if="parseInt(trainingType) === 2"/>
    </b-container>
</template>
<script>
import TraineeForm from './TraineeForm'
import TraineerForm from './TraineerForm'

export default {
    components: {
        TraineeForm,
        TraineerForm
    },
    data () {
        return {
            trainingType: this.$store.state.ExternalUserIrrigation.panelObj.trainingType
        }
    }
}
</script>

<template>
<div>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                  <b-row>
                      <b-col>
                          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                              <div class="titleDiv">
                              </div>
                          </list-report-head>
                      </b-col>
                  </b-row>
                  <b-col lg="12">
                    <b-row>
                      <div class="card card w-100 text-center">
                        <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;"> {{ $t('externalTraining.profile_info')+ ' ' + this.$t('globalTrans.details') }}</h5>
                        </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                          <b-tbody>
                            <b-tr>
                              <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('user.namel') }}</b-th>
                              <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('externalUserIrrigation.father_name') }}</b-th>
                              <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.name_of_father_husband_bn : profile.name_of_father_husband }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" class="w-50 line-height-five" scope="row">{{$t('globalTrans.mother_name')}}</b-th>
                              <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.mother_name_bn : profile.mother_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('externalUserIrrigation.nid') }}</b-th>
                              <b-td style="">
                                  <slot v-if="profile.nid">
                                  {{ $n(profile.nid, { useGrouping: false }) }}
                                  </slot>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{$t('elearning_iabm.nid_attach')}}</b-th>
                              <b-td style=""><a  v-if="profile.nid_attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/iab/personal_info/nid/' + profile.nid_attachment" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a></b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                              <b-td style="">{{ profile.date_of_birth | dateFormat }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('globalTrans.mobile') }}</b-th>
                              <b-td style="">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping: false }) }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style=" " scope="row">{{ $t('externalTraining.email') }}</b-th>
                              <b-td style=" ">{{ ($i18n.locale=='bn') ? profile.email : profile.email }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('externalTraining.gender') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.gender_name_bn : profile.gender_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style=" " scope="row">{{ $t('externalTraining.mat_status') }}</b-th>
                              <b-td style=" ">{{ ($i18n.locale=='bn') ? profile.marital_name_bn : profile.marital_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('externalTraining.blood_group') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.blood_name_bn : profile.blood_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('externalTraining.religion') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.religion_name_bn : profile.religion_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{$t('globalTrans.photo')}}</b-th>
                              <b-td>
                                <img v-if="profile.image" :src="trainingElearningServiceBaseUrl + 'storage/' + profile.image" style="width: 80px; height: 80px; border-radius: 50% !important;" class="center" alt="Profile Image">
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{$t('globalTrans.signature')}}</b-th>
                              <b-td>
                                <!-- <img v-if="profile.signature !== '' && profile.signature !== null" class="img w-50" style="height: 100px;width:100px;" :src="trainingElearningServiceBaseUrl + 'download-attachment?file=' + profile.signature" alt="Signature Image"> -->
                                <img v-if="profile.signature" :src="trainingElearningServiceBaseUrl + 'storage/' + profile.signature" style="width: 80px; height: 40px; border-radius: 0px !important;" class="center" alt="Signature Image">
                              </b-td>
                            </b-tr>
                          </b-tbody>
                      </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{
                          $t('elearning_iabm.emergency_contact_information') }}</h5>
                      </div>
                    </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <b-thead>
                        <b-tr>
                          <b-th style="" class="w-50 line-height-five" scope="row">{{
                            $t('elearning_iabm.emergency_contact_person') }}</b-th>
                          <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ?
                            profile.emergency_name_bn : profile.emergency_name }}</b-td>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-th style="" class="w-50 line-height-five" scope="row">{{
                            $t('elearning_iabm.emergency_contact_relation') }}</b-th>
                          <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ?
                            profile.emergency_relation_bn : profile.emergency_relation }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th style="" scope="row">{{ $t('elearning_iabm.emergency_contact_number') }}</b-th>
                          <b-td style="">
                            <slot v-if="profile.emergency_contact">
                              {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.emergency_contact, { useGrouping:
                              false }) }}
                            </slot>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>
                  <div class="card card w-100 text-center">
                  <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                    <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">    {{ $t('externalTraining.present_address') }}</h5>
                  </div>
                  </div>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                            <b-tr>
                              <b-th style="" scope="col">{{ $t('org_pro.area_type') }}</b-th>
                              <b-th style="" scope="col">{{ ($i18n.locale=='bn') ? profile.area_name_bn : profile.area_name }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <b-th style="" class="w-50" scope="row">{{ $t('org_pro_division.division') }}</b-th>
                              <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.division_name_bn : profile.division_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="" scope="row">{{ $t('org_pro_district.district') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.district_name_bn : profile.district_name }}</b-td>
                            </b-tr>
                            <b-tr v-if="profile.address.pre_area_type_id == 3||2">
                              <b-th style="" scope="row">{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.upazilla_name_bn : profile.upazilla_name }}</b-td>
                            </b-tr>
                            <b-tr v-if="profile.address.pre_area_type_id == 3">
                              <b-th style="" scope="row">{{ $t('org_pro_union.union') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.union_name_bn : profile.union_name }}</b-td>
                            </b-tr>
                            <b-tr v-if="profile.address.pre_area_type_id == 1">
                              <b-th style="" scope="row">{{ $t('org_pro.city_corporation') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.cityCorporation_name_bn : profile.cityCorporation_name }}</b-td>
                            </b-tr>
                            <b-tr v-if="profile.address.pre_area_type_id == 3||2">
                              <b-th style="" scope="row">{{ $t('org_pro.ward') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name }}</b-td>
                            </b-tr>
                            <b-tr v-if="profile.address.pre_area_type_id == 2">
                              <b-th style="" scope="row">{{ $t('org_pro.pauroshoba') }}</b-th>
                              <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</b-td>
                            </b-tr>
                          </b-tbody>
                      </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                  <div class="card card w-100 text-center">
                  <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                  <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.parmanent_address') }}</h5>
                  </div>
                  </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('org_pro.area_type') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.per_area_name_bn : profile.per_area_name }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('org_pro_division.division') }}</b-th>
                            <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.per_division_name_bn : profile.per_division_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('org_pro_district.district') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_district_name_bn : profile.per_district_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.per_area_type_id == 3||2">
                            <b-th style="" scope="row">{{ $t('org_pro_upazilla.upazilla')}} </b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_upazilla_name_bn : profile.per_upazilla_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.per_area_type_id == 3">
                            <b-th style="" scope="row">{{ $t('org_pro_union.union') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_union_name_bn : profile.per_union_name }}</b-td>
                          </b-tr>
                        <b-tr v-if="profile.address.per_area_type_id == 1">
                            <b-th style="" scope="row">{{ $t('org_pro.city_corporation') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_cityCorporation_name_bn : profile.per_cityCorporation_name }}</b-td>
                          </b-tr>
                        <b-tr v-if="profile.address.per_area_type_id == 3||2">
                            <b-th style="" scope="row">{{ $t('org_pro.ward') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.per_area_type_id == 2">
                            <b-th style="" scope="row">{{ $t('org_pro.pauroshoba') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</b-td>
                          </b-tr>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>
                  <div class="card card w-100 text-center">
                  <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                  <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.academic_info') }} </h5>
                  </div>
                  </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('externalTraining.highest_degree') }}</b-th>
                            <b-td style="" class="w-50">
                                {{ ($i18n.locale=='bn') ? profile.name_of_highest_degree_bn : profile.name_of_highest_degree }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row" class="w-50">{{ $t('externalTraining.board_university') }}</b-th>
                            <b-td style="" class="w-50">
                                {{ ($i18n.locale=='bn') ? profile.board_bn : profile.board }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row" class="w-50">{{ $t('elearning_iabm.passing_year') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot v-if="profile.passing_year">
                                {{ profile.passing_year }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row" class="w-50">{{ $t('elearning_iabm.group') }}</b-th>
                            <b-td style="" class="w-50">
                                {{ ($i18n.locale=='bn') ? profile.prof_group_bn : profile.prof_group }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row" class="w-50">{{ $t('elearning_iabm.cgpa') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot v-if="profile.cgpa">
                                {{ profile.cgpa }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr>
                              <b-th style="" scope="row">{{$t('globalTrans.attachment')}}</b-th>
                              <b-td style=""><a  v-if="profile.certificate_attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/iab/personal_info/certificate/' + profile.certificate_attachment" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a></b-td>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                        </b-tbody>
                      </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>
                  <div class="card card w-100 text-center">
                  <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                  <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.professional_info') }}</h5>
                  </div>
                  </div>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('elearning_iabm.profession_type') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.proType_name_bn : profile.proType_name
                              }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-if="profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2" >
                            <b-th style="" scope="col">{{ $t('elearning_iabm.job_type') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.job_name_bn : profile.job_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.job_type_id == 1" >
                            <b-th style="" scope="col">{{ $t('elearning_iabm.cader_name') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.professional_info.cader_name_bn : profile.professional_info.cader_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.job_type_id == 1" >
                            <b-th style="" scope="row">{{ $t('elearning_iabm.bcs_batch') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.batch">
                                {{ profile.professional_info.batch }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 2 || profile.professional_info.profession_type == 3">
                            <b-th style="" scope="col">{{ $t('globalTrans.org_name_address') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.professional_info.other_org_name_bn : profile.professional_info.other_org_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.not_here_office == 0">
                            <b-th style="" scope="col">{{ $t('elearning_config.office_type') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.office_type_name_bn : profile.office_type_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.not_here_office == 0">
                            <b-th style="" scope="col">{{ $t('globalTrans.office') }}</b-th>
                            <b-td style="" scope="col">{{ (($i18n.locale=='bn') ? profile.office_name_bn : profile.office_name) + ', ' + getOrgName(profile.professional_info.org_id)
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.not_here_office == 1">
                            <b-th class="w-50" style="" scope="col">{{ $t('globalTrans.office') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ ($i18n.locale=='bn') ? profile.professional_info.office_name_bn : profile.professional_info.office_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1">
                            <b-th style="" scope="row">{{ $t('externalTraining.office_mail') }}</b-th>
                            <b-td style="">
                                {{ ($i18n.locale=='bn') ? profile.professional_info.office_mail_address_bn : profile.professional_info.office_mail_address}}
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.not_here_designation == 0" >
                            <b-th style="" class="w-50" scope="row">{{ $t('elearning_iabm.designation') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot v-if="profile.professional_info.designation_id">
                                {{ ($i18n.locale=='bn') ? profile.designation_name_bn : profile.designation_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 2 || profile.professional_info.profession_type == 3 || profile.professional_info.not_here_designation == 1">
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.designation') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.professional_info.designation_bn : profile.professional_info.designation_en}}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2">
                            <b-th style="" scope="row">{{ $t('externalTraining.service_id') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.service_id">
                                {{ $n(profile.professional_info.service_id, { useGrouping: false }) }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2">
                            <b-th style="" scope="row">{{ $t('externalTraining.pay_grade') }}</b-th>
                            <b-td style="">
                              {{ ($i18n.locale=='bn') ? profile.grade_name_bn : profile.grade_name }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('elearning_iabm.designation_c') }}</b-th>
                            <b-td style="">
                                {{ ($i18n.locale=='bn') ? profile.professional_info.controlling_authority_bn : profile.professional_info.controlling_authority }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('elearning_iabm.telephone_c') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.telephone">
                                {{ profile.professional_info.telephone }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('elearning_iabm.email_c') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.email">
                                {{ profile.professional_info.email }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <!-- <b-tr>
                              <b-th style="" class="w-50" scope="row">{{ $t('elearning_iabm.office_name') }}</b-th>
                              <b-td style="" class="w-50">
                                <slot v-if="profile.professional_info.designation_id">
                                  {{ ($i18n.locale=='bn') ? profile.professional_info.office_name_bn : profile.professional_info.office_name }}
                                </slot>
                              </b-td>
                            </b-tr> -->
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                </b-row>
                </b-col>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
import ExportPdf from './export_pdf_details_profile'
import { profileShow } from '../../api/routes'
export default {
    components: {
        ListReportHead
    },
  created () {
    this.loadData()
  },
  data () {
    return {
      load: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      profile: {
            registration_for: '',
            name: '',
            name_bn: '',
            datee_of_birth: '',
            email: '',
            nid: '',
            mobile: '',
            gender: '',
            marital_status: '',
            blood_group: '',
            religion: '',
            name_of_highest_degree: '',
            board: '',
            professional_info: {
                org_id: 0,
                designation_id: 0,
                grade_id: 0,
                service_id: '',
                controlling_authority: '',
                telephone: '',
                email: '',
                office_mail_address: ''
            },
            address: {
                pre_area_type_id: 0,
                pre_division_id: 0,
                pre_district_id: 0,
                pre_upazilla_id: 0,
                pre_ward_id: '',
                pre_union_id: '',
                pre_city_corporation_id: '',
                pre_pauroshoba_id: '',
                same_as_pre_address: '',
                per_area_type_id: 0,
                per_division_id: 0,
                per_district_id: 0,
                per_upazilla_id: 0,
                per_city_corporation_id: '',
                per_pauroshoba_id: '',
                per_ward_id: '',
                per_union_id: ''
            },
            is_draft: 1,
            status: 1
        }
    }
  },
  computed: {
        loading () {
            return this.$store.state.commonObj.loading
        },
    userTypeList () {
        const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
    },
    genderList () {
    const genderList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ', text_en: 'Male', text_bn: 'পুরুষ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা', text_en: 'Female', text_bn: 'মহিলা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
    ]
    return genderList
    },
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    maritalList () {
    const status = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত', text_en: 'Married', text_bn: 'বিবাহিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত', text_en: 'Unmarried', text_bn: 'অবিবাহিত' }
    ]
    return status
    },
    bloodGroupList () {
    const bloodGroupList = [
        { value: 1, text: 'A+', text_bn: 'এ+' },
        { value: 2, text: 'A-', text_bn: 'এ-' },
        { value: 3, text: 'B+', text_bn: 'বি+' },
        { value: 4, text: 'B-', text_bn: 'বি-' },
        { value: 5, text: 'O+', text_bn: 'ও+' },
        { value: 6, text: 'O-', text_bn: 'ও-' },
        { value: 7, text: 'AB+', text_bn: 'এবি+' },
        { value: 8, text: 'AB-', text_bn: 'এবি-' }
    ]
    return bloodGroupList
    },
    religionList () {
    const religionList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম', text_en: 'Muslim', text_bn: 'মুসলিম' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু', text_en: 'Hindu', text_bn: 'হিন্দু' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান', text_en: 'Christian', text_bn: 'খ্রিস্টান' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ', text_en: 'Buddhist', text_bn: 'বৌদ্ধ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
    ]
    return religionList
    },
    fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainerEvaluationList: function () {
    return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('elearning_iabm.registration_for'), class: 'text-left' },
            { label: this.$t('globalTrans.name'), class: 'text-left' },
            { label: this.$t('globalTrans.email'), class: 'text-left' },
            { label: this.$t('globalTrans.mobile'), class: 'text-left' },
            { label: this.$t('externalUserIrrigation.date_of_birth'), class: 'text-left' },
            { label: this.$t('globalTrans.nid'), class: 'text-left' },
            // { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'user_type_name_bn' },
            { key: 'name_bn' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'date_of_birth' },
            { key: 'nid' },
            // { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'user_type_name' },
            { key: 'name' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'date_of_birth' },
            { key: 'nid' },
            // { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
},
  methods: {
    getOrgName (id) {
      const trainingType = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, profileShow).then(response => {
            if (response.success) {
                this.profile = this.getCustomDataList(response.data)
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        })
    },
    getCustomDataList (item) {
            const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
            if (typeof userTypeObj !== 'undefined') {
              item.user_type_name = userTypeObj.text_en
              item.user_type_name_bn = userTypeObj.text_bn
            } else {
              item.user_type_name = ''
              item.user_type_name_bn = ''
            }
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_info.org_id))
            if (typeof orgObj !== 'undefined') {
              item.org_name = orgObj.text_en
              item.org_name_bn = orgObj.text_bn
            } else {
              item.org_name = ''
              item.org_name_bn = ''
            }
            const jobTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.jobTypeList.find(job => parseInt(job.value) === parseInt(item.professional_info.job_type_id))
            if (typeof jobTypeObj !== 'undefined') {
              item.job_name = jobTypeObj.text_en
              item.job_name_bn = jobTypeObj.text_bn
            } else {
              item.job_name = ''
              item.job_name_bn = ''
            }
            const designationObj = this.$store.state.ExternalUserIrrigation.commonObj.masterDesignationList.find(designation => designation.value === item.professional_info.designation_id)
            if (typeof designationObj !== 'undefined') {
              item.designation_name = designationObj.text_en
              item.designation_name_bn = designationObj.text_bn
            } else {
              item.designation_name = ''
              item.designation_name_bn = ''
            }
            const gradeObj = this.$store.state.ExternalUserIrrigation.commonObj.gradeList.find(grade => grade.value === parseInt(item.professional_info.grade_id))
            if (typeof gradeObj !== 'undefined') {
              item.grade_name = gradeObj.text_en
              item.grade_name_bn = gradeObj.text_bn
            } else {
              item.grade_name = ''
              item.grade_name_bn = ''
            }
            const areaObj = this.$store.state.commonObj.wardTypeList.find(area => area.id === parseInt(item.address.pre_area_type_id))
            if (typeof areaObj !== 'undefined') {
              item.area_name = areaObj.name
              item.area_name_bn = areaObj.name_bn
            } else {
              item.area_name = ''
              item.area_name_bn = ''
            }
            const perareaObj = this.$store.state.commonObj.wardTypeList.find(area => area.id === parseInt(item.address.per_area_type_id))
            if (typeof perareaObj !== 'undefined') {
              item.per_area_name = perareaObj.name
              item.per_area_name_bn = perareaObj.name_bn
            } else {
              item.per_area_name = ''
              item.per_area_name_bn = ''
            }
            const divisionObj = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(division => division.value === parseInt(item.address.pre_division_id))
            if (typeof divisionObj !== 'undefined') {
              item.division_name = divisionObj.text_en
              item.division_name_bn = divisionObj.text_bn
            } else {
              item.division_name = ''
              item.division_name_bn = ''
            }
            const perdivisionObj = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(division => division.value === parseInt(item.address.per_division_id))
            if (typeof perdivisionObj !== 'undefined') {
              item.per_division_name = perdivisionObj.text_en
              item.per_division_name_bn = perdivisionObj.text_bn
            } else {
              item.per_division_name = ''
              item.per_division_name_bn = ''
            }
            const districtObj = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(district => district.value === parseInt(item.address.pre_district_id))
            if (typeof divisionObj !== 'undefined') {
              item.district_name = districtObj.text_en
              item.district_name_bn = districtObj.text_bn
            } else {
              item.district_name = ''
              item.district_name_bn = ''
            }
            const perdistrictObj = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(district => district.value === parseInt(item.address.per_district_id))
            if (typeof perdivisionObj !== 'undefined') {
              item.per_district_name = perdistrictObj.text_en
              item.per_district_name_bn = perdistrictObj.text_bn
            } else {
              item.per_district_name = ''
              item.per_district_name_bn = ''
            }
            const upazillaObj = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(upazilla => upazilla.value === parseInt(item.address.pre_upazilla_id))
            if (typeof upazillaObj !== 'undefined') {
              item.upazilla_name = upazillaObj.text_en
              item.upazilla_name_bn = upazillaObj.text_bn
            } else {
              item.upazilla_name = ''
              item.upazilla_name_bn = ''
            }
            const perupazillaObj = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(perupazilla => perupazilla.value === parseInt(item.address.per_upazilla_id))
            if (typeof perupazillaObj !== 'undefined') {
              item.per_upazilla_name = perupazillaObj.text_en
              item.per_upazilla_name_bn = perupazillaObj.text_bn
            } else {
              item.per_upazilla_name = ''
              item.per_upazilla_name_bn = ''
            }
            const cityCorporationObj = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === parseInt(item.address.pre_city_corporation_id))
            if (typeof cityCorporationObj !== 'undefined') {
              item.cityCorporation_name = cityCorporationObj.text_en
              item.cityCorporation_name_bn = cityCorporationObj.text_bn
            } else {
              item.cityCorporation_name = ''
              item.cityCorporation_name_bn = ''
            }
            const percityCorporationObj = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === parseInt(item.address.pre_city_corporation_id))
            if (typeof percityCorporationObj !== 'undefined') {
              item.per_cityCorporation_name = percityCorporationObj.text_en
              item.per_cityCorporation_name_bn = percityCorporationObj.text_bn
            } else {
              item.per_cityCorporation_name = ''
              item.per_cityCorporation_name_bn = ''
            }
            const unionObj = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(union => union.value === parseInt(item.address.pre_union_id))
            if (typeof unionObj !== 'undefined') {
              item.union_name = unionObj.text_en
              item.union_name_bn = unionObj.text_bn
            } else {
              item.union_name = ''
              item.union_name_bn = ''
            }
            const perunionObj = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(union => union.value === parseInt(item.address.per_union_id))
            if (typeof perunionObj !== 'undefined') {
              item.per_union_name = perunionObj.text_en
              item.per_union_name_bn = perunionObj.text_bn
            } else {
              item.per_union_name = ''
              item.per_union_name_bn = ''
            }
            const wardObj = this.$store.state.ExternalUserIrrigation.commonObj.wardList.find(ward => ward.value === parseInt(item.address.pre_ward_id))
            if (typeof wardObj !== 'undefined') {
              item.ward_name = wardObj.text_en
              item.ward_name_bn = wardObj.text_bn
            } else {
              item.ward_name = ''
              item.ward_name_bn = ''
            }
            const perwardObj = this.$store.state.ExternalUserIrrigation.commonObj.wardList.find(ward => ward.value === parseInt(item.address.per_ward_id))
            if (typeof perwardObj !== 'undefined') {
              item.per_ward_name = perwardObj.text_en
              item.per_ward_name_bn = perwardObj.text_bn
            } else {
              item.per_ward_name = ''
              item.per_ward_name_bn = ''
            }
            const pauroshobaObj = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.find(per => per.value === parseInt(item.address.pre_pauroshoba_id))
            if (typeof pauroshobaObj !== 'undefined') {
              item.pauroshoba_name = pauroshobaObj.text_en
              item.pauroshoba_name_bn = pauroshobaObj.text_bn
            } else {
              item.pauroshoba_name = ''
              item.pauroshoba_name_bn = ''
            }
            const perpauroshobaObj = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.find(per => per.value === parseInt(item.address.per_pauroshoba_id))
            if (typeof perpauroshobaObj !== 'undefined') {
              item.per_pauroshoba_name = perpauroshobaObj.text_en
              item.per_pauroshoba_name_bn = perpauroshobaObj.text_bn
            } else {
              item.per_pauroshoba_name = ''
              item.per_pauroshoba_name_bn = ''
            }
            const genderObj = this.genderList.find(per => per.value === item.gender)
            if (typeof genderObj !== 'undefined') {
              item.gender_name = genderObj.text_en
              item.gender_name_bn = genderObj.text_bn
            } else {
              item.gender_name = ''
              item.gender_name_bn = ''
            }
            const maritalObj = this.maritalList.find(per => per.value === item.marital_status)
            if (typeof maritalObj !== 'undefined') {
              item.marital_name = maritalObj.text_en
              item.marital_name_bn = maritalObj.text_bn
            } else {
              item.marital_name = ''
              item.marital_name_bn = ''
            }
            const bloodObj = this.bloodGroupList.find(blood => blood.value === item.blood_group)
            if (typeof bloodObj !== 'undefined') {
              item.blood_name = bloodObj.text
              item.blood_name_bn = bloodObj.text_bn
            } else {
              item.blood_name = ''
              item.blood_name_bn = ''
            }
            const religionObj = this.religionList.find(religion => religion.value === item.religion)
            if (typeof religionObj !== 'undefined') {
              item.religion_name = religionObj.text_en
              item.religion_name_bn = religionObj.text_bn
            } else {
              item.religion_name = ''
              item.religion_name_bn = ''
            }
            const proTypeObj = this.professionTypeList.find(per => per.value === parseInt(item.professional_info.profession_type))
            if (typeof proTypeObj !== 'undefined') {
              item.proType_name = proTypeObj.text_en
              item.proType_name_bn = proTypeObj.text_bn
            } else {
              item.proType_name = ''
              item.proType_name_bn = ''
            }
            const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(item.professional_info.office_id))
            if (typeof officeObj !== 'undefined') {
              item.office_name = officeObj.text_en
              item.office_name_bn = officeObj.text_bn
            } else {
              item.office_name = ''
              item.office_name_bn = ''
            }
            const officeTypeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.professional_info.office_type_id))
            if (typeof officeTypeObj !== 'undefined') {
              item.office_type_name = officeTypeObj.text_en
              item.office_type_name_bn = officeTypeObj.text_bn
            } else {
              item.office_type_name = ''
              item.office_type_name_bn = ''
            }
          return item
    },
    pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Profile Info Details' : 'প্রোফাইলের তথ্য বিবরণ'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
